<template>
    <div class="div_principal">
        <div class="div_imagem">
        </div>
        <div class="div_login">
            <img src="/images/logo.png" id="logo"/>

            <div class="formulario">
                <div class="inputContainer">
                    <label>Usuário</label>
                    <input type="text" class="input" v-model="email"/>
                </div>
                <div class="inputContainer">
                    <label>Senha</label>
                    <input type="password" class="input" v-model="password"/>
                    <a>Esqueceu a senha?</a>
                </div>
                    <div class="inputContainer" v-if="erro_login">
                        <InlineMessage style="width: 100%; text-align: left !important;">Usuário ou senha inválidos!</InlineMessage>
                    </div>

                <button type="button" class="btn_entrar" @click="login()">Entrar</button>
                


            </div>

            <div>Não tem uma conta? <a href="/cadastrar-empresa">Cadastre-se</a></div>
        </div>
    </div>
</template>

<script>
import Api from '../service/api';
export default {
    mounted(){
        this.api = new Api();
        document.body.classList.add('div_principal')
    },
    methods: {
        login() {
            this.api.login(this.email,this.password).then(data =>{
                if((data.status == 200 || data.status == 201) && 'token' in data.data) {
                    this.$storage.setStorageSync("jwt", data.data.token);
                    this.$storage.setStorageSync("empresa",data.data.empresas.empresa_principal)
                    this.$storage.setStorageSync("nome",data.data.user.name)
                    this.$storage.setStorageSync("user", data.data.user)
                    this.$router.push('/')
                }
                else {
                    this.erro_login = true;
                }
            }).catch(error => {
                this.erro_login = true;
                console.log(error);
            })
        },
    },
    data() {
        return {
            email: '',
            password: '',
            checked: false,
            erro_login:false
        }
    },

}
</script>

<style>
.div_imagem{
    background-image: url("/images/img_login.png");
    width:70%;
    height: 100%;
    float:left;
    background-size:100% 100%;
}
.pi-eye {
    transform:scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform:scale(1.6);
    margin-right: 1rem;
}
#app{
    height: 100%;
}
.div_principal{
    height:100%;
}
.div_login{
    float: left;
    width: 30%;
    text-align:center;
    padding-top:100px;
}
#logo{
    width: 50%;
}
.input{
    width:100%;
}
.btn_entrar{
    width: 80%;
    background-color:#4B89EE;
    color:#fff;
    padding:12px;
    border:none;
    border-radius:5px;
    font-size: 12pt;
    font-weight: bold;
}
.inputContainer{
    margin-left:auto;
    margin-right:auto;
    text-align:left;
    width:80%;
        margin-bottom:20px;

}
.formulario{
    margin-top:50px;
    text-align: center;
}

input{
    border: #EFEFEF solid 1px;
    background-color:#FBFBFB;
    padding:15px;
    font-size: 11pt;
    margin-bottom:5px;
    margin-top:5px;
}
button{
    margin-top:40px;
    cursor:pointer;
}
label{
    margin-top:50px;
    font-size:12pt;
    font-weight: bold;

}
a{
    color:#4B89EE;
    font-weight: 400;
}
</style>
